<template>
  <div class="row">
    <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
      <!-- <router-link to="/employees"> -->
        <div class="card dash-widget" @click="pushRoute('translation')">
          <div class="card-body">
            <span class="dash-widget-icon"><i class="fa fa-cubes"></i></span>
            <div class="dash-widget-info" style="justify-content: center">
              <h4 style=" margin-bottom: 0px; font-size: 22px;color: #1f1f1f">Translation</h4>
            </div>
          </div>
        </div>
      <!-- </router-link> -->
    </div>
    <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
      <div class="card dash-widget">
        <div class="card-body">
          <span class="dash-widget-icon"><i class="fa fa-cubes"></i></span>
          <div class="dash-widget-info" style="justify-content: center">
            <h4 style=" margin-bottom: 0px; font-size: 22px;color: #1f1f1f">Dubbing</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
      <div class="card dash-widget">
        <div class="card-body">
          <span class="dash-widget-icon"><i class="fa fa-cubes"></i></span>
          <div class="dash-widget-info" style="justify-content: center">
            <h4 style=" margin-bottom: 0px; font-size: 22px;color: #1f1f1f">Montage</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
      <div class="card dash-widget">
        <div class="card-body">
          <span class="dash-widget-icon"><i class="fa fa-cubes"></i></span>
          <div class="dash-widget-info" style="justify-content: center">
            <h4 style=" margin-bottom: 0px; font-size: 22px;color: #1f1f1f">Filming</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">

      <div class="card dash-widget">
        <div class="card-body">
          <span class="dash-widget-icon"><i class="fa fa-cubes"></i></span>
          <div class="dash-widget-info" style="justify-content: center">
            <h4 style=" margin-bottom: 0px; font-size: 22px;color: #1f1f1f">Script</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">

      <div class="card dash-widget">
        <div class="card-body">
          <span class="dash-widget-icon"><i class="fa fa-cubes"></i></span>
          <div class="dash-widget-info" style="justify-content: center">
            <h4 style=" margin-bottom: 0px; font-size: 22px;color: #1f1f1f">Financial System</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">

      <div class="card dash-widget" @click="pushRoute('hr')">
        <div class="card-body">
          <span class="dash-widget-icon"><i class="fa fa-cubes"></i></span>
          <div class="dash-widget-info" style="justify-content: center">
            <h4 style=" margin-bottom: 0px; font-size: 22px;color: #1f1f1f">HR</h4>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6 col-sm-6 col-lg-6 col-xl-3">
      <div class="card dash-widget">
        <div class="card-body">
          <span class="dash-widget-icon"><i class="fa fa-cubes"></i></span>
          <div class="dash-widget-info" style="justify-content: center">
            <h4 style=" margin-bottom: 0px; font-size: 22px;color: #1f1f1f">Customers</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
export default {
  data() {
    return {
    };
  },
  components: {},
  methods:{
    pushRoute(name){
      this.$router.replace({ name: `/${name}` })
    }
  }
};
</script>
<style>
.dash-widget:hover {
  cursor: pointer;
  box-shadow: 0 0 10px #80808059;
  transition: all .3s;
}

.dash-widget {
  transition: all .3s;
}

/* a{
  color: #1f1f1f !important
} */
</style>
