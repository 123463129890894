import { defineStore } from "pinia";
import { BaseState } from "@/store/BaseState";
import DepartmentService from "../services/DepartmentService";

export const useDepartmentStore = defineStore("departmentStore", {
    state: () => ({
        departmentBase: new BaseState(),
        departmentModel: {
            id: '',
            name: '',
        },
        departmentList: [],
        show: false,
    }),
    getters: {
    },
    actions: {
        clearDepartmentModel() {
            this.departmentModel.id = ''
            this.departmentModel.name = ''
        },
        setDepartmentModel(item) {
            this.departmentModel.id = item.id
            this.departmentModel.name = item.name
        },
        addDepartment(formData) {
            this.departmentBase.loading = true;
            DepartmentService.add(formData).then((res) => {
                this.departmentBase.loading = false
                this.show = false
                this.fetchAllDepartment()
                this.clearDepartmentModel()
            })
                .catch((err) => {
                    this.departmentBase.loading = false
                    this.departmentBase.error = true
                });
        },
        fetchAllDepartment() {
            this.departmentBase.loadingFetch = true
            DepartmentService.getAll().then((res) => {
                this.departmentList = res.data.list.reverse()
                this.departmentBase.loadingFetch = false
                this.departmentBase.errorFetch = false
            })
                .catch((err) => {
                    this.departmentBase.loadingFetch = false
                    this.departmentBase.errorFetch = true
                });
        },
        deleteDepartment(id) {
            this.departmentBase.loading = true
            DepartmentService.delete(id).then((res) => {
                this.departmentBase.loading = false
                this.departmentList.splice(this.departmentBase.editedIndex, 1)
                this.departmentBase.dialogDeleteModel = false
            })
                .catch((err) => {
                    this.departmentBase.loading = false
                    this.departmentBase.error = true
                })
        },
        getDepartmentById(id) {
            this.departmentBase.loading = true
            DepartmentService.getById(id).then((res) => {
                this.departmentBase.loading = false
                this.departmentModel.id = res.data.list.id
                this.departmentModel.name = res.data.list.name
            })
                .catch((err) => {
                    this.departmentBase.loading = false
                    this.departmentBase.error = true
                })
        },
        editDepartment(id, formData) {
            this.departmentBase.loading = true;
            DepartmentService.edit(id, formData).then((res) => {
                this.departmentBase.loading = false
                this.departmentBase.ifEdit = false
                this.show = false
                this.fetchAllDepartment()
                this.clearDepartmentModel()
            })
                .catch((err) => {
                    this.departmentBase.loading = false
                    this.departmentBase.error = true
                });
        },
    },
});
