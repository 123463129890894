import Vue from 'vue'
import VueRouter from 'vue-router'
// import Login from '@/modules/auth/views/Login.vue'
import Dashboard from "@/modules/Dashboard.vue"
import NotFound from "@/modules/NotFound.vue"
// import Employee from "@/views/HR/Employee.vue"
// import Department from "@/views/HR/Department.vue"
// import Designation from "@/views/HR/Designation.vue"
import Clients from "@/views/HR/Clients.vue"

Vue.use(VueRouter)

/**
 * @desc this function is pull all routes from modules , the .routes extension is required required in route files
 **/
const routesFiles = require.context("../", true, /\.routes.js$/);
const routesModules = routesFiles.keys().reduce((routesModules, routePath) => {
  const value = routesFiles(routePath);
  routesModules.push(value.default);
  return routesModules;
}, []);


const routes = [
  //modules routes
  ...routesModules,
  //fallback route
  {
    path: "*",
    name: "not-found",
    component: NotFound,
    meta: { title: "Page not found" },
  },
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
    meta: { title: "Home" },
  },
  {
    path: '/hr/client',
    name: 'hr-client',
    component: Clients,
    meta: { title: "Clients" },
  }
];


// const routes = [
// {
//   path: '/',
//   name: 'login',
//   component: Login
// },
// {
//   path: '/dashboard',
//   name: 'dashboard',
//   component: Dashboard
// },
// {
//   path: '/hr/employees',
//   name: 'hr-employees',
//   component: Employee
// },
// {
//   path: '/hr/departments',
//   name: 'hr-departments',
//   component: Department
// },
// {
//   path: '/hr/designations',
//   name: 'hr-designations',
//   component: Designation
// },
// {
//   path: '/hr/clients',
//   name: 'hr-clients',
//   component: Clients
// }
// ]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    }
  },
})


//change the title of the browser tab according to route's meta title
const DEFAULT_TITLE = process.env.VUE_APP_PROJECT_NAME;
router.afterEach((to) => {
  // Use next tick to handle router history correctly
  Vue.nextTick(() => {
    if (to.meta.title) document.title = to.meta.title;
    else document.title = DEFAULT_TITLE;
  });
});


// router.beforeEach((to, from, next) => {
//   const user = localStorage.getItem('user')
//   if (user !== null) {
//     if (to.path == "/login") {
//       next("/");
//     } else {
//       next();
//     }
//   } else if (to.path !== "/login") {
//     next("/login");
//   } else {
//     next();
//   }
// });



router.afterEach(() => {
  window.scrollTo(0, 0);
});


export default router
