<template>
    <v-dialog v-model="showUpdated" max-width="600px" :persistent="persistent">
        <v-card style="padding:10px 0px 20px">
            <v-card-title style=" justify-content: center;">
                <span class="text-h5 color-primary">{{ title }}</span>
            </v-card-title>
            <v-divider />
            <v-card-text>
                <v-container>
                    <slot name="inputs"></slot>
                </v-container>
            </v-card-text>
            <v-divider />
            <v-card-actions style="column-gap: 10px;justify-content: center;">
                <!-- <v-spacer></v-spacer> -->
                <slot name="actions"></slot>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
// import { useDialogStore } from "@/store/DialogStore"
// import { mapWritableState } from "pinia"
export default {
    name: "FormDialog",
    props: {
        title: {
            type: String,
            required: true
        },
        persistent: {
            type: Boolean, 
            default: false
        },
        show: {
            type: Boolean,
        },
    },
    computed: {
        // ...mapWritableState(useDialogStore, ["show"]),
        showUpdated: {
            get(){
                return this.show
            },
            set(value){
                this.$emit('update:show', value)
            }
        }
    },
};
</script>
<style>
.v-dialog{
    border-radius: 20px;
}
</style>
