<template>
    <div class="login account-page" style="height:100vh !important;">
        <!-- Main Wrapper -->
        <div class="main-wrapper">
            <div class="account-content">
                <div class="container">
                    <!-- Account Logo -->
                    <div class="account-logo">
                        <!-- <router-link to="/index"> -->
                        <img src="@/assets/img/logo-unicorn.png" alt="Unicorn" />
                        <!-- </router-link> -->
                    </div>
                    <!-- /Account Logo -->
                    <div class="account-box">
                        <div class="account-wrapper">
                            <h3 class="account-title">Login</h3>
                            <p class="account-subtitle">Access to our dashboard</p>
                            <!-- Account Form -->
                            <!-- <Form class="login">
                                <b-form-group id="email" label="Email Address">
                                    <b-form-input
                                        v-model="email"
                                        :rules="[requiredRule, emailRule]"
                                    ></b-form-input>

                                    <div class="error-section" v-if="$v.email.$error">
                                        <span class="error">{{ emailErrors[0] }}</span>
                                    </div>
                                </b-form-group>
                                <div style="margin-bottom: 25px;">
                                    <div class="row">
                                        <div class="col-lg-6">
                                            <label for="password">Password</label>
                                        </div>
                                        <div
                                            class="col-lg-6"
                                            style="text-align: end; color: #8e8e8e !important;"
                                        >
                                            <span style="cursor: pointer;">Forgot password?</span>
                                        </div>
                                    </div>
                                    <b-form-input id="password" v-model="password" type="password"></b-form-input>
                                    <div class="error-section" v-if="$v.password.$error">
                                        <span class="error">{{ passwordErrors[0] }}</span>
                                    </div>
                                </div>
                                <div class="form-group text-center">
                                    <button
                                        class="btn btn-primary account-btn"
                                        type="button"
                                        @click="submit()"
                                        :disabled="isSubmit && !response"
                                    >
                                        Login
                                        <b-spinner
                                            v-if="isSubmit && !response"
                                            label="Text Centered"
                                            style="width: 1.5rem;height: 1.5rem;"
                                        ></b-spinner>
                                    </button>
                                </div>
                                <div class="account-footer">
                                    <p>
                                        Don't have an account yet?
                                        <router-link to="/">Register</router-link>
                                    </p>
                                </div>
                            </Form>-->
                            <v-form style="margin-top: 20px" ref="form">
                                <v-text-field
                                    type="email"
                                    prepend-inner-icon="mdi-email"
                                    outlined
                                    v-model="email"
                                    :rules="[requiredRule, emailRule]"
                                    label="Email"
                                    @keyup.enter="submit()"
                                    validate-on-blur
                                ></v-text-field>
                                <v-text-field
                                    style="margin: 20px 0px"
                                    outlined
                                    prepend-inner-icon="mdi-key"
                                    v-model="password"
                                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                                    @click:append="showPassword = !showPassword"
                                    :type="showPassword ? 'text' : 'password'"
                                    :rules="[requiredRule, passwordRule]"
                                    label="Password"
                                    @keyup.enter="submit()"
                                    validate-on-blur
                                ></v-text-field>
                                <submit-btn
                                    @click="submit"
                                    :loading="authStore.loading"
                                    name="Login"
                                    :ifBlock="true"
                                    style="margin-bottom: 30px"
                                />
                                <div class="account-footer">
                                    <p>
                                        Don't have an account yet?
                                        <router-link to="/register">Register</router-link>
                                    </p>
                                </div>
                            </v-form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- /Main Wrapper -->
    </div>
</template>
<script>
import FormValidationRulesMixin from "@/mixins/FormValidationRulesMixin.vue"
import { useAuthStore } from "../store/AuthStore"
import SubmitBtn from "@/components/buttons/SubmitBtn"
export default {
    components: {
        SubmitBtn
    },
    data: () => ({
        email: '',
        password: '',
        response: false,
        isSubmit: false,
    }),
    setup() {
        const authStore = useAuthStore()
        return { authStore }
    },
    mixins: [FormValidationRulesMixin],
    data: () => ({
        email: null,
        password: null,
        showPassword: false,
    }),
    methods: {
        submit() {
            if (this.$refs.form.validate()) {
                this.sendData()
            }
        },
        sendData() {
            const formData = new FormData()
            formData.append('email', this.email)
            formData.append('password', this.password)
            this.authStore.login(formData)
        },

        // submit() {
        //     this.$v.$touch()
        //     if (!this.$v.$error) {
        //         this.isSubmit = true
        //         console.log(this.isSubmit)
        //         console.log(this.response)
        //         this.sendData()
        //     }
        // },
        // sendData() {
        //     const formData = new FormData()
        //     formData.append('email', this.email)
        //     formData.append('password', this.password)
        //     this.axios.post(this.$store.state.url + "/api/login", formData)
        //         .then(res => {
        //             this.response = true
        //             console.log(res)
        //             if (res.data.message == "userSuccessfullySignedIn") {
        //                 this.axios.defaults.headers.common["Authorization"] = `Bearer ${res.data.list[0].token}`
        //                 localStorage.setItem("token", res.data.list[0].token)
        //                 localStorage.setItem("auth", true)
        //                 this.$router.replace({ name: 'dashboard' })
        //             }
        //         }).catch(error => {
        //             console.log(error)
        //             this.response = true
        //             this.email = ''
        //             this.password = ''
        //             this.$v.$reset()
        //             Vue.$toast.open({
        //                 message: 'The information entered is incorrect, please try again',
        //                 type: 'error',
        //                 position: 'top-right',
        //             });
        //         });
        // },
    },
    mounted(){
    }
}
</script>
<style>
@import url(@/assets/css/main.css);

</style>