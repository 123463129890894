<template>
    <div class="Clientss">
        <div class="main-wrapper">
            <hr-sidebar></hr-sidebar>
            <div class="page-wrapper">
                <!-- header -->
                <div class="content container-fluid">
                    <div class="page-header">
                        <div class="row align-items-center">
                            <div class="col">
                                <h3 class="page-title">Clients</h3>
                                <ul class="breadcrumb">
                                    <li class="breadcrumb-item"><router-link to="/index">Dashboard</router-link></li>
                                    <li class="breadcrumb-item active">Clients</li>
                                </ul>
                            </div>
                            <div class="col-auto float-end ms-auto">
                                <a class="btn add-btn" @click="addModal = true"><i class="fa fa-plus"></i> Add
                                    Clients</a>
                            </div>
                        </div>
                    </div>
                    <!-- table -->
                    <div class="row" v-if="load">
                        <div class="col-md-12">
                            <div>
                                <b-form-group>
                                    <div class="row">
                                        <div class="col-lg-4">
                                            <label for="search">Search:</label>
                                            <b-form-input id="search" v-model="search" @input="filterData" />
                                        </div>
                                    </div>
                                </b-form-group>
                                <b-table ref="table" striped hover :items="filteredData" :fields="fields"
                                    :current-page="currentPage" :per-page="perPage" @page-change="pageChanged">
                                    <template v-slot:cell(actions)="row">
                                        <b-button size="sm" variant="danger" @click="deleteClients(row.item)">
                                            Delete
                                        </b-button>
                                        <b-button size="sm" variant="primary" @click="editClients(row.item)">
                                            Edit
                                        </b-button>
                                    </template>
                                </b-table>
                                <b-pagination v-model="currentPage" :total-rows="filteredData.length" :per-page="perPage"
                                    align="center" />
                            </div>
                        </div>
                    </div>
                    <div class="container" v-else>
                        <div class="row" style=" justify-content: center;align-items: center;height: 300px;">
                            <div class="spinner-border" style="width: 50px;height: 50px;"></div>
                        </div>
                    </div>
                </div>
                <!-- Add Clients Modal -->
                <b-modal size="lg" v-model="addModal">
                    <template #modal-header="{ close }">
                        <h5 class="modal-title">Add Clients</h5>
                        <button @click="close()" type="button" class="modal-close" aria-label="Close">
                            <span aria-hidden="true" class="span-close">&times;</span>
                        </button>
                    </template>
                    <div class="modal-body">
                        <form>
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label>First Name <span class="text-danger">*</span></label>
                                        <input class="form-control" type="text" v-model="first_name">
                                        <div class="error-section" v-if="$v.first_name.$error">
                                            <span class="error">{{ firstErrors[0] }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label>Last Name <span class="text-danger">*</span></label>
                                        <input class="form-control" type="text" v-model="last_name">
                                        <div class="error-section" v-if="$v.last_name.$error">
                                            <span class="error">{{ lastErrors[0] }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label>Email <span class="text-danger">*</span></label>
                                        <input class="form-control" type="email" v-model="email">
                                        <div class="error-section" v-if="$v.email.$error">
                                            <span class="error">{{ emailErrors[0] }}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label>Shortcut</label>
                                        <input class="form-control" type="text" v-model="shortcut">
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label>City</label>
                                        <input class="form-control" type="text" v-model="city">
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label>Address</label>
                                        <input class="form-control" type="text" v-model="address">
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label>Price Of minute</label>
                                        <input class="form-control" type="text" v-model="price_minute">
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group">
                                        <label>Phone</label>
                                        <input class="form-control" type="text" v-model="phone">
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                    <template #modal-footer>
                        <div class="submit-section">
                            <button type="button" class="btn btn-primary submit-btn" @click="submit_add()"
                                :disabled="isSubmit && !response">
                                Submit
                                <div class="spinner-border text-light" v-if="isSubmit && !response"
                                    style="width: 20px;height: 20px; margin-left: 5px;"></div>
                            </button>
                        </div>
                    </template>
                </b-modal>
                <!-- Edit Clients Modal -->
                <b-modal v-model="editModal">
                    <template #modal-header="{ close }">
                        <h5 class="modal-title">Edit Clients</h5>
                        <button @click="close()" type="button" class="modal-close" aria-label="Close">
                            <span aria-hidden="true" class="span-close">&times;</span>
                        </button>
                    </template>
                    <div class="modal-body">
                        <form>
                            <div class="form-group">
                                <label>Clients Name <span class="text-danger">*</span></label>
                                <input class="form-control" type="text" v-model="edit_name">
                            </div>
                        </form>
                    </div>
                    <template #modal-footer>
                        <div class="submit-section">
                            <button type="button" class="btn btn-primary submit-btn"
                                @click="submit_edit(edit_name, edit_id)" :disabled="isSubmit && !response">
                                Submit
                                <div class="spinner-border text-light" v-if="isSubmit && !response"
                                    style="width: 20px;height: 20px; margin-left: 5px;"></div>
                            </button>
                        </div>
                    </template>
                </b-modal>
                <!-- Delete Clients Modal -->
                <b-modal v-model="deleteModal">
                    <template #modal-header="{ close }">
                        <h5 class="modal-title">Delete Clients</h5>
                        <button @click="close()" type="button" class="modal-close" aria-label="Close">
                            <span aria-hidden="true" class="span-close">&times;</span>
                        </button>
                    </template>
                    <div class="modal-body">
                        <div class="form-header">
                            <p style="font-size: 18px;">Are you sure want to delete?</p>
                        </div>
                    </div>
                    <template #modal-footer>
                        <div class="col-6">
                            <button type="button" :disabled="isSubmit && !response" class="btn btn-primary submit-btn"
                                @click="submit_delete(selectedClients)" style="width: -webkit-fill-available;">Delete
                                <div class="spinner-border text-light" v-if="isSubmit && !response"
                                    style="width: 20px;height: 20px; margin-left: 5px;"></div>
                            </button>
                        </div>
                        <div class="col-6">
                            <a @click="deleteModal = false" class="btn btn-primary cancel-btn">Cancel</a>
                        </div>
                    </template>
                </b-modal>
            </div>
        </div>
    </div>
</template>
<script>
import { validationMixin } from 'vuelidate'
import { required, email } from 'vuelidate/lib/validators'
import HrSidebar from '@/modules/hr/core/components/HrSidebar.vue'
import Vue from "vue"
export default {
    mixins: [validationMixin],

    name: 'Clients',
    components: {
        HrSidebar
    },
    data() {
        return {
            edit_name: '',
            currentPage: 1,
            perPage: 5,
            data: [],
            selectedClients: {
                name: '',
                id: null,
            },
            first_name: '',
            last_name: '',
            email: '',
            shortcut: '',
            city: '',
            phone: '',
            price_minute: '',
            address: '',
            response: false,
            isSubmit: false,
            edit_id: '',
            load: false,
            addModal: false,
            deleteModal: false,
            editModal: false,
            search: '',
            fields: [
                { key: 'id', label: 'ID' },
                { key: 'firstname', label: 'First Name' },
                { key: 'lastname', label: 'Last Name' },
                { key: 'shortcut', label: 'Shortcut' },
                { key: 'email', label: 'Email' },
                { key: 'price for minute', label: 'Price Of Minute' },
                { key: 'city', label: 'City' },
                { key: 'phone', label: 'Phone' },
                { key: 'actions', label: 'Actions' }
            ]
        }
    },
    validations: {
        first_name: { required },
        last_name: { required },
        email: { required, email },
    },
    computed: {
        firstErrors() {
            const errors = []
            if (!this.$v.first_name.$dirty) return errors
            !this.$v.first_name.required && errors.push('First Name is required')
            return errors
        },
        lastErrors() {
            const errors = []
            if (!this.$v.last_name.$dirty) return errors
            !this.$v.last_name.required && errors.push('Last Name is required')
            return errors
        },
        emailErrors() {
            const errors = []
            if (!this.$v.email.$dirty) return errors
            !this.$v.email.required && errors.push('Email is required')
            !this.$v.email.email && errors.push('Email is invalid')
            return errors
        },
        filteredData() {
            return this.data.filter(item => {
                const searchRegex = new RegExp(this.search, 'i');
                return Object.values(item).some(value => searchRegex.test(value));
            });
        }
    },
    methods: {
        filterData() {
            this.$nextTick(() => {
                this.currentPage = 1;
            });
        },
        pageChanged(page) {
            this.currentPage = page;
        },
        validateInput() {
            this.nameState = this.dep_name_add.length > 0 ? true : false
            return this.nameState
        },
        editClients(client) {
            this.edit_name = client.name
            this.edit_id = client.id
            this.editModal = true
        },
        deleteClients(client) {
            this.selectedClients = client
            this.deleteModal = true
        },
        submit_add() {
            this.$v.$touch()
            if (!this.$v.$error) {
                this.isSubmit = true
                const formData = new FormData()
                formData.append('firstname', this.first_name)
                formData.append('lastname', this.last_name)
                formData.append('country', this.city)
                formData.append('phone', this.phone)
                formData.append('address', this.address)
                formData.append('price_minute', this.price_minute)
                formData.append('role_users_id', 3)
                this.axios.post(this.$store.state.url + '/api/create_client', formData).then((res) => {
                    this.response = true
                    console.log(res.data)
                    if (res.data.message == 'Successfully Created') {
                        Vue.$toast.open({
                            message: 'Added Successfully',
                            type: 'success',
                            position: 'top-right',
                        });
                        this.get_data()
                        this.addModal = false
                    }
                }).catch(error => {
                    console.log(error)
                })
            }
        },
        submit_edit(name, id) {
            this.isSubmit = true
            const formData = new FormData()
            formData.append('name', name)
            formData.append('company_id', id)
            this.axios.post(this.$store.state.url + '/api/edit_company', formData).then((res) => {
                this.response = true
                console.log(res.data)
                if (res.data.message == "Success") {
                    Vue.$toast.open({
                        message: 'Edited Successfully',
                        type: 'success',
                        position: 'top-right',
                    });
                    this.get_data()
                    this.editModal = false
                }
            }).catch(error => {
                console.log(error)
            })
        },
        submit_delete(item) {
            this.isSubmit = true
            const formData = new FormData()
            formData.append('client_id', item.id)
            this.axios.post(this.$store.state.url + '/api/delete_client', formData).then((res) => {
                this.response = true
                console.log(res.data)
                if (res.data.message == "Successfully Deleted") {
                    Vue.$toast.open({
                        message: 'Deleted Successfully',
                        type: 'success',
                        position: 'top-right',
                    });
                    this.get_data()
                    this.deleteModal = false
                }
            }).catch(error => {
                console.log(error)
            })
        },
        get_data() {
            this.axios.get(this.$store.state.url + '/api/clients').then((res) => {
                this.load = true
                this.data = res.data.list
                console.log(this.data)
            }).catch(error => {
                console.log(error)
            })
        }
    },
    mounted() {
        this.get_data()
    },
}
</script>
<style>
.modal-footer {
    flex-wrap: nowrap !important;
}
</style>