import Client from "@/clients/AxiosClient";

export default {
    add(formData) {
        return Client.post("/create_company", formData);
    },

    getAll() {
        return Client.get("/companies");
    },

    getById(id) {
        return Client.get(`/company?company_id=${id}`);
    },

    edit(formData) {
        return Client.post(`/edit_company`, formData);
    },

    delete(id) {
        return Client.delete(`/delete_company?company_id=${id}`);
    },
};