import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"450px","persistent":""},model:{value:(_vm.dialogDeleteModel),callback:function ($$v) {_vm.dialogDeleteModel=$$v},expression:"dialogDeleteModel"}},[_c(VCard,[_c(VSpacer),_c('div',{staticClass:"d-flex justify-content-center align-aitems-center",staticStyle:{"padding-top":"30px"}},[_c(VIcon,{attrs:{"color":"error"}},[_vm._v("mdi-window-close")])],1),_c(VCardTitle,{staticClass:"justify-content-center",staticStyle:{"padding":"20px 0px"}},[_vm._v("Are you sure?")]),_c(VCardText,{staticClass:"justify-content-center"},[_c('p',{staticClass:"text-muted",staticStyle:{"text-align":"center"}},[_vm._v(" Do you really want to delete these record? This process cannot be undone. ")])]),_c(VCardActions,{staticStyle:{"padding-bottom":"30px"}},[_c(VSpacer),_c(VBtn,{attrs:{"color":"grey","outlined":""},on:{"click":_vm.close}},[_vm._v("Cancel")]),_c(VBtn,{attrs:{"loading":_vm.loading,"color":"error"},on:{"click":_vm.deleteItemConfirm}},[_vm._v("Confrim")]),_c(VSpacer)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }