<template>
    <div class="index">
        <div class="main-wrapper">
            <div class="page-wrapper" style="margin: 0px">
                <div class="content container-fluid">
                    <pageheader />
                    <widget />
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Pageheader from "@/components/Dashboard/pageheader.vue"
import Widget from "@/components/Dashboard/widget.vue"
import { useAuthStore } from "@/modules/auth/store/AuthStore"

export default {
    components: {
        Pageheader,
        Widget
    },
    setup() {
        const authStore = useAuthStore()
        return { authStore }
    },
    mounted(){
        this.authStore.getCom_()
    },
}
</script>