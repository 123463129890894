<template>
    <form-dialog
        :title="!departmentBase.ifEdit ? titleAdd : titleEdit"
        :persistent="true"
        :show.sync="show"
    >
        <template v-slot:inputs>
            <v-form ref="form">
                <v-row>
                    <v-col cols="12">
                        <text-input :text.sync="departmentModel.name" label="Department Name" :readonly="departmentBase.ifEdit"/>
                    </v-col>
                    <!-- <v-col cols="12">
                        <text-input :text.sync="departmentModel.value" label="department.value" />
                    </v-col> -->
                </v-row>
            </v-form>
        </template>
        <template v-slot:actions>
            <cancel-btn @click="cancel" />
            <submit-btn @click="submit" :loading="departmentBase.loading" />
        </template>
    </form-dialog>
</template>

<script>
import FormDialog from "@/components/dialogs/FormDialog.vue"
import { useDepartmentStore } from "../store/DepartmentStore"
import { useLangStore } from "@/store/LangStore"
import { mapState, mapActions, mapWritableState } from "pinia"
import FormValidationRulesMixin from "@/mixins/FormValidationRulesMixin.vue"
import SubmitBtn from '@/components/buttons/SubmitBtn.vue'
import CancelBtn from "@/components/buttons/CancelBtn.vue"
import TextInput from '@/components/inputs/TextInput.vue'

export default {
    components: {
        FormDialog,
        SubmitBtn,
        TextInput,
        CancelBtn,
    },
    mixins: [FormValidationRulesMixin],
    data() {
        return {
            titleAdd: "Add Department",
            titleEdit: "Edit Department",
        };
    },
    computed: {
        ...mapState(useLangStore, ["getDirection"]),
        ...mapState(useDepartmentStore, ["departmentBase", "departmentModel"]),
        ...mapWritableState(useDepartmentStore, ["show"]),
    },
    methods: {
        ...mapActions(useDepartmentStore, ['addDepartment', 'editDepartment', 'clearDepartmentModel']),
        submit() {
            if (this.$refs.form.validate()) {
                this.departmentBase.ifEdit
                    ? this.editDepartment(this.departmentModel.id, this.createFormData())
                    : this.addDepartment(this.createFormData())
            }
        },
        createFormData() {
            const formData = new FormData()
            formData.append('name', this.departmentModel.name)
            return formData
        },
        cancel() {
            this.show = false
            this.departmentBase.ifEdit = false
            this.clearDepartmentModel()
            this.$refs.form.resetValidation()
        }
    }
};
</script>
