import { User } from "@/modules/auth/models/UserModel";
import { defineStore } from "pinia";
import authRepository from "../services/AuthRepository";
import router from "@/router"

export const useAuthStore = defineStore("authStore", {
  state: () => ({
    user:
      localStorage.getItem("user") === null  ? null : JSON.parse(localStorage.getItem("user")),
    loading: false,
    error: false,
    message: '',
  }),
  getters: {
    isAuthenticated() {
      return this.user !== null;
    },
    getUser() {
      return this.user !== null ? this.user : null;
    },
    getId() {
      return this.user !== null ? this.user.id : null;
    },
    getToken() {
      return this.user !== null ? this.user.token : null;
    },
  },
  actions: {
    getCom_(){
      authRepository.getCom().then((res)=>{
        // console.log(res)
      })
    },
    loginLocally(data){
      this.user = new User(data.user.id, data.user.email, data.user.username, data.user.role_users_id,  data.token)
      console.log(this.user)
      localStorage.setItem("user", JSON.stringify(this.user))
    },
    login(formData) {
      this.loading = true;
      authRepository
        .login(formData)
        .then((res) => {
          this.loading = false
          // console.log(res)
          this.loginLocally(res.data.list[0])
          router.push("/")
          // router.go(router.currentRoute);
        })
        .catch((err) => {
          console.log(err)
          this.loading = false

          // this.error = true
          // this.message = err.res.data.errors[0][0]
        });
    },
    logoutLocally() {
      this.user = null
      localStorage.clear()
      router.push("/login")
    },
  },
});
