<template>
    <!-- <sidebar> -->
    <div class="sidebar" id="sidebar">
        <div class="sidebar-inner" id="style-15">
            <perfect-scrollbar class="scroll-area" :settings="settings" @ps-scroll-y="scrollHanle">
                <div id="sidebar-menu" class="sidebar-menu">
                    <ul class="sidebar-vertical">
                        <li class="menu-title">
                            <!-- <span>Main</span> -->
                        </li>
                        <!-- <li class="submenu">
                            <a href="javascript:;"><i class="la la-dashboard"></i> <span> Dashboard</span> <span
                                    class="menu-arrow"></span></a>
                            <ul style="display: none;">
                                <li><router-link to="/dashboard">Admin Dashboard</router-link></li>
                            </ul>
                        </li> -->
                        <li class="menu-title">
                            <span>HR</span>
                        </li>
                        <!-- <li class="submenu">
                            <a href="javascript:;"
                                v-bind:class="{ 'active': currentPath == 'employees-list' || currentPath == 'shift-list' }"
                                class="noti-dot"><i class="la la-user"></i> <span> Employees</span> <span
                                    class="menu-arrow"></span></a>
                            <ul style="display: none;">
                                <li><router-link v-bind:class="{ 'active': currentPath == 'employees-list' }"
                                        to="/hr/employees">Employees</router-link></li>
                                <li><router-link to="/hr/departments">Departments</router-link></li>
                                <li><router-link to="/hr/designations">Designations</router-link></li>
                            </ul>
                        </li> -->
                        <li>
                            <router-link to="/hr/employees" v-bind:class="{ 'active': currentPath == 'employees-list' }"><i
                                    class="la la-users"></i> <span>Employees</span></router-link>
                        </li>
                        <li>
                            <router-link to="/hr/departments" v-bind:class="{ 'active': currentPath == 'departments-list' }"><i
                                    class="la la-users"></i> <span>Departments</span></router-link>
                        </li>
                        <li>
                            <router-link to="/hr/designations" v-bind:class="{ 'active': currentPath == 'designations-list' }"><i
                                    class="la la-users"></i> <span>Designations</span></router-link>
                        </li>
                        <li>
                            <router-link to="/hr/clients" v-bind:class="{ 'active': currentPath == 'clients-list' }"><i
                                    class="la la-users"></i> <span>Clients</span></router-link>
                        </li>
                        <!-- <li class="menu-title">
                            <router-link v-bind:class="{ 'active': currentPath == 'clients-list' }"
                                to="/hr/clients">Clients</router-link>
                        </li> -->
                    </ul>
                </div>
            </perfect-scrollbar>
        </div>
    </div>
    <!-- </sidebar> -->
</template>
<script>
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
export default {
    components: {
        PerfectScrollbar,
    },
    mounted() {
        $('#sidebar-menu a').on('click', function (e) {
            if ($(this).parent().hasClass('submenu')) {
                e.preventDefault();
            }
            if (!$(this).hasClass('subdrop')) {
                $('ul', $(this).parents('ul:first')).slideUp(350);
                $('a', $(this).parents('ul:first')).removeClass('subdrop');
                $(this).next('ul').slideDown(350);
                $(this).addClass('subdrop');
            } else if ($(this).hasClass('subdrop')) {
                $(this).removeClass('subdrop');
                $(this).next('ul').slideUp(350);
            }
        });
        $('#sidebar-menu ul li.submenu a.active').parents('li:last').children('a:first').addClass('active').trigger('click');

    },
    computed: {
        currentPath() {
            return this.$route.name;
        }
    },
    data() {
        return {
            settings: {
                suppressScrollX: true,
            },
            activeClass: 'active',
        };
        //  isactive : true
    },
    methods: {
        scrollHanle(evt) {

        }
    },
}

</script>

