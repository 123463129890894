<template>
    <div class="departments">
        <div class="main-wrapper">
            <hr-sidebar></hr-sidebar>
            <div class="page-wrapper">
                <!-- header -->
                <div class="content container-fluid">
                    <div class="page-header">
                        <div class="row align-items-center">
                            <div class="col">
                                <h3 class="page-title">{{title}}</h3>
                                <ul class="breadcrumb">
                                    <li class="breadcrumb-item">
                                        <router-link to="/">HR</router-link>
                                    </li>
                                    <li class="breadcrumb-item active">{{title}}</li>
                                </ul>
                            </div>
                            <div class="col-auto float-end ms-auto">
                                <a class="btn add-btn" @click="showUpdated = true">
                                    <i class="fa fa-plus"></i> Add {{title}}
                                </a>
                            </div>
                        </div>
                    </div>
                    <div class>
                        <slot name="dataTable"></slot>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import HrSidebar from '@/modules/hr/core/components/HrSidebar.vue'
export default {
    name: "HrLayout",
    props: {
        title:{
            required: true
        }
    },
    components: {
        HrSidebar
    },
    computed: {
        showUpdated: {
            get() {
                return this.show
            },
            set(value) {
                this.$emit('update:show', value)
            }
        }
    },
};

</script>

<style lang="scss">
@import url(@/assets/css/main.css);

</style>
