import { render, staticRenderFns } from "./DepartmentListView.vue?vue&type=template&id=673d8da2&"
import script from "./DepartmentListView.vue?vue&type=script&lang=js&"
export * from "./DepartmentListView.vue?vue&type=script&lang=js&"
import style0 from "./DepartmentListView.vue?vue&type=style&index=0&id=673d8da2&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports